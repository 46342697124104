<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">
        <statistics-card
          icon="LayersIcon"
          :statistic="shorten(counter.queue_ticket)"
          statistic-title="Queued Tickets"
          :color="status_color[10]"
          :icon-right="true"
        />
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">
        <statistics-card
          icon="LayersIcon"
          :statistic="shorten(counter.ongoing_ticket)"
          statistic-title="Ongoing Tickets"
          :color="status_color[20]"
          :icon-right="true"
        />
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">
        <statistics-card
          icon="LayersIcon"
          :statistic="shorten(counter.finished_ticket)"
          statistic-title="Finished Ticket"
          :color="status_color[30]"
          :icon-right="true"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full md:w-2/3 mb-base">
        <vx-card title="Ticket Updates">
          <vs-table :data="tickets" @selected="navigate" class="table-borderless">
            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data" :data="tr">
                <vs-td :data="data[indextr].item">
                  {{ data[indextr].item }}
                </vs-td>

                <vs-td :data="data[indextr].treatment" class="text-primary">
                  <b>{{ data[indextr].treatment }}</b>
                </vs-td>

                <vs-td :data="data[indextr].created_at" class="text-teal">
                  {{ fromNow(data[indextr].created_at) }}
                </vs-td>

                <vs-td :data="data[indextr].next_status">
                  <span class="flex items-center px-2 py-1 rounded"><div class="h-3 w-3 rounded-full mr-2" :class="'bg-' + status_color[data[indextr].next_status]" /></span>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </div>
      <div class="vx-col w-full md:w-1/3 mb-base">
        <vx-card title="Todays Treatment">
          <vs-list v-if="treatments.length !== 0">
            <span v-for="treatment in treatments" :key="treatment.id">
              <vs-list-header :title="`${treatment.name} (${treatment.tickets.length})`" />
              <!-- <vs-list-item v-for="ticket in " :subtitle="ticket.item" :key="ticket.id" /> -->
            </span>
          </vs-list>
          <span v-else>No data yet</span>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import StatisticsCard from '@/components/cards/StatisticsCard.vue';
import InvoiceRepo from '@/repositories/invoice_repo';
import DashboardRepo from '@/repositories/dashboard_repo';
import { shorten } from '@/utils/helpers';
import { INVOICE_STATUS_NAME, STATUS_COLOR, REPORT_CHART_OPTIONS } from '@/utils/constants';

export default {
  data: () => ({
    status_name: INVOICE_STATUS_NAME,
    status_color: STATUS_COLOR,
    counter: {
      queue_ticket: 0,
      ongoing_ticket: 0,
      finished_ticket: 0
    },
    tickets: [],
    treatments: [],
    chartData: [],
    chartOptions: REPORT_CHART_OPTIONS,
    leaderboard: []
  }),
  methods: {
    async loadCounter() {
      await InvoiceRepo.countTicket().then(({ data: content }) => {
        content.forEach((item) => {
          if (item.status === 10) {
            this.counter.queue_ticket = +item.count;
          } else if (item.status === 20) {
            this.counter.ongoing_ticket = +item.count;
          } else if (item.status === 30) {
            this.counter.finished_ticket = +item.count;
          }
        });
      });
      await InvoiceRepo.listLog({ page: 1, per_page: 7 }).then(({ data: { data: logs } }) => {
        this.tickets = logs;
      });
      await DashboardRepo.analyticTreatment().then(({ data: treatments }) => {
        this.treatments = treatments;
      });
    },
    shorten,
    fromNow(date) {
      return moment(date).fromNow();
    },
    navigate(item) {
      this.$router.push(`/invoices/${item.invoice_no}`);
    }
  },
  components: {
    StatisticsCard
  },
  async beforeMount() {
    await this.loadCounter();
  }
};
</script>
