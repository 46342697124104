<template>
  <vx-card class="overflow-hidden">
    <div slot="no-body">
      <div class="p-6 pb-0" :class="{'flex justify-between flex-row-reverse items-center': iconRight}">
        <feather-icon :icon="icon" class="p-3 inline-flex rounded-full" :class="[`text-${color}`, {'mb-4': !iconRight}]" :style="{background: `rgba(var(--vs-${color}),.15)`}" />
        <div>
          <h2 class="mb-1 font-bold">
            {{ statistic }}
          </h2>
          <span>{{ statisticTitle }}</span>
        </div>
      </div>
      <br>
    </div>
  </vx-card>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    statistic: {
      type: [Number, String],
      required: true
    },
    statisticTitle: {
      type: String
    },
    color: {
      type: String,
      default: 'primary'
    },
    iconRight: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    themePrimaryColor() {
      this.$refs.apexChart.updateOptions({ theme: { monochrome: { color: this.getHex() } } });
    }
  },
  computed: {
    themePrimaryColor() {
      return this.$store.state.themePrimaryColor;
    }
  }
};
</script>
